.table-container,
.table-container>div {
  overflow: visible;
}

.line::before {
  position: absolute;
  left: -35px;
  top: -5px;
  content: "";
  display: flex;
  height: 32px;
  border-bottom: 1px solid rgb(205, 206, 209);
  width: 35px;
}